import React, { FC } from 'react';

interface RatingDotsProps {
  rating: number; // e.g., 4.5
}

const RatingDots: FC<RatingDotsProps> = ({ rating }) => {
  const fullBubbles = Math.floor(rating); // Full bubbles
  const hasHalfBubble = rating % 1 !== 0; // Check for half bubble
  const totalBubbles = 5; // Total number of bubbles

  return (
    <svg
      viewBox="0 0 128 24"
      width="88"
      height="16"
      aria-labelledby="rating-bubbles"
      className="bubble-rating"
    >
      <title id="rating-bubbles">{`${rating} of ${totalBubbles} bubbles`}</title>

      {Array.from({ length: totalBubbles }).map((_, index) => {
        const isFull = index < fullBubbles;
        const isHalf = index === fullBubbles && hasHalfBubble;

        return (
          <path
            key={index}
            d="M 12 0C5.388 0 0 5.388 0 12s5.388 12 12 12 12-5.38 12-12c0-6.612-5.38-12-12-12z"
            transform={`translate(${index * 26} 0)`}
            fill={isFull ? '#34A853' : isHalf ? 'url(#halfFill)' : '#E0E0E0'}
          />
        );
      })}

      {/* Define gradient for half-filled bubbles */}
      <defs>
        <linearGradient id="halfFill" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="50%" style={{ stopColor: '#34A853', stopOpacity: 1 }} />
          <stop offset="50%" style={{ stopColor: '#E0E0E0', stopOpacity: 1 }} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default RatingDots;
